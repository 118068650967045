import { applyMiddleware, createStore, compose } from "redux"
import createSagaMiddleware from "redux-saga"
import logger from "redux-logger"
import rootReducer from "../reducers"
import rootSaga from "../sagas/"

const configureStore = () => {
    const sagaMiddleware = createSagaMiddleware()
    const middlewares = [sagaMiddleware]

    if (process.env.NODE_ENV === "development") {
        middlewares.push(logger)
    }

    const store = createStore(
        rootReducer,
        compose(
            applyMiddleware(...middlewares),
            // window.__REDUX_DEVTOOLS_EXTENSION__ &&
            //   window.__REDUX_DEVTOOLS_EXTENSION__()
        )
    )
    sagaMiddleware.run(rootSaga)

    return store
}

export default configureStore
